import * as React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import { WalletNotConnectedError } from 'src/components/wallet/wallet-adapter-base';
import { useConnection, useWallet } from 'src/components/wallet/wallet-adapter-react';
import { Keypair, SystemProgram, Transaction } from '@solana/web3.js';
import { FC, useCallback } from 'react';

const AboutPage = () => (
  <Layout>
    <Seo title="BLUME About" />
    <h1>About BLUME Finance</h1>
    <p>Content coming soon.</p>
    <div class="disclaim">
    <h3>Disclaimer Information</h3>
    <span class="disclaim">
    <h4>Disclaimer of Liability</h4>
    <p>
      Neither BUME Finance, the BLUME.finance website, nor its contributors
      shall be held liable for any improper or incorrect use of the information described
      and/or contained herein and assumes no responsibility for anyone's use of the information.
      In no event shall BLUME Finance, the BLUME.finance website or its contributors
      be liable for any direct, indirect, incidental, special, exemplary,
      or consequential damages (including, but not limited to:
      procurement of substitute goods or services; loss of use, data, or profits;
      or business interruption) however caused and on any theory of liability,
      whether in contract, strict liability, tort (including negligence or otherwise),
      or any other theory arising in any way out of the use of this system,
      even if advised of the possibility of such damage.
      This disclaimer of liability applies to any damages or injury,
      whether based on alleged breach of contract, tortious behavior,
      negligence or any other cause of action,
      including but not limited to damages or injuries caused by any failure of performance,
      error, omission, interruption, deletion, defect, delay in operation or transmission,
      computer virus, communication line failure, and/or theft,
      destruction or unauthorized access to, alteration of, or use of any record.
    </p>
    <h4> Indemnification</h4>
    <p>
      User agrees to defend, indemnify, and hold harmless, BLUME Finance,
      the BLUME.finance website, its contributors, any entity jointly created by them,
      their respective affiliates and their respective directors, officers, employees,
      and agents from and against all claims and expenses, including attorneys&#39; fees,
      arising out of the use of the on-line service by user or user&#39;s account.
    </p>
    <h4>Disclaimer of Warranties/Accuracy and Use of Data/Computer Viruses</h4>
    <p>
      Although the data found using the BLUME Finance website access systems have been produced
      and processed from sources believed to be reliable, no warranty expressed or implied
      is made regarding accuracy, adequacy, completeness, legality, reliability or
      usefulness of any information. This disclaimer applies to both isolated and aggregate
      uses of the information. BLUME Finance and the BLUME.finance website provide this information
      on an "AS IS" basis. All warranties of any kind, express or implied, including but not limited
      to the IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
      freedom from contamination by computer viruses and non-infringement of proprietary
      rights ARE DISCLAIMED. Changes may be periodically added to the information herein;
      these changes may or may not be incorporated in any new version of the publication.
      If the user has obtained information from the BLUME.finance website from a source other
      than BLUME Finance, the user must be aware that electronic data can be altered subsequent
      to original distribution. Data can also quickly become out-of-date. It is recommended
      that the user pay careful attention to the contents of any metadata associated with a file,
      and that the originator of the data or information be contacted with any questions
      regarding appropriate use. If the user finds any errors or omissions,
      we encourage the user to report them to BLUME Finance.
    </p>
    <h4>Disclaimer of Endorsement</h4>
    <p>
      BLUME Finance and the BLUME.finance website are distributors of content sometimes
      supplied by third parties and users. Any opinions, advice, statements, services, offers,
      or other information or content expressed or made available by third parties,
      including information providers, users, or others, are those of the respective author(s)
      or distributor(s) and do not necessarily state or reflect those of BLUME Finance.
      Reference herein to any specific commercial products, process, or service by trade name,
      trademark, manufacturer, or otherwise, does not constitute or imply its endorsement,
      recommendation, or favoring by BLUME Finance or the BLUME.finance website, and such
      reference shall not be used for advertising or product endorsement purposes.
    </p>
    <h4>Disclaimer with Regards to Links</h4>
    <p>
      Neither BLUME Finance, the BLUME.finance website nor its contributors is responsible
      for the contents of any off-site pages that reference, or that are referenced by,
      the BLUME.finance website. The user specifically acknowledges that neither BLUME Finance,
      the BLUME.finance website nor its contributors is liable for any defamatory,
      offensive, misleading or illegal conduct of other users, links, or third parties
      and that the risk of injury from the foregoing rests entirely with the user.
    </p><p>
      Links from the BLUME.finance website on the World Wide Web to other sites, or from
      other sites to the BLUME Finance website, do not constitute an endorsement by BLUME Finance
      or the BLUME.finance wesbite. These links are for convenience only. It is the responsibility
      of the user to evaluate the content and usefulness of information obtained from other sites.
    </p><p>
      This web site contains links to other sites. When you link to another site,
      you are no longer on BLUME Finance&#39;s website and are subject to the privacy
      policies and other practices of the new site. BLUME Finance and/or its ownwers, officers
      or directors have no control over and is not responsible for the information,
      practices or content of these or any other sites and your participation in promotions
      or services of any kind with any third party found on or though this site,
      or your correspondence or business dealings of any kind with any third party found
      on or through this site, including without limitation, payments, warranties,
      or representations associated with such dealings, are solely between you and that third party.
    </p><p>
      BLUME Finance and/or its owners, officers or directors do not, by way of its links
      to other sites, endorse, adopt, recommend, promote or support products, positions,
      statements made or taken by parties controlling the other sites.
    </p><p>
      BLUME Finance and/or its owners, officers or directors are not responsible or liable
      for any loss or damage of any sort incurred as the result of your dealings
      with third party advertisers, or as the result of the presence of such advertisers on the web site.
    </p>
    </span>
    </div>
  </Layout>
)

export default AboutPage
